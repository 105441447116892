export const DEVELOPMENT_ENVIRONMENT = "development";
export const INTEGRATION_ENVIRONMENT = "integration";
export const STAGING_ENVIRONMENT = "staging";
export const PRODUCTION_ENVIRONMENT = "production";
export const WHITELABEL_ENVIRONMENT = "whitelabel";

export const devPattern =
  /(https:+\/\/)([A-Za-z0-9]+)(-local|\.dev)\.(canopy|clientportal)\.(dev|ninja)/;
export const stagePattern =
  /(https:\/\/)([A-Za-z0-9\-]+)[\-\.]([A-Za-z0-9]+)\.(canopy|clientportal)\.(dev|ninja)/;
export const prodPattern =
  /(https:\/\/)([A-Za-z0-9\-]+)\.(canopytax|clientportal)\.com/;

export const devUrls = {
  web: (base, suffix, domain, topLevel, currentOrigin) =>
    `https://${base}${suffix}.${domain}.${topLevel}`,
  api: (base, suffix, domain, topLevel, currentOrigin) => `/wg`,
  auth: (base, suffix, domain, topLevel, currentOrigin) =>
    base === "skymonkey"
      ? `https://app${suffix}.${domain}.${topLevel}`
      : currentOrigin,
  workflow: (base, suffix, domain, topLevel, currentOrigin) => ``,
  skymonkey: (base, suffix, domain, topLevel, currentOrigin) =>
    `https://skymonkey${suffix}.canopy.${topLevel}`,
};

export const stageUrls = {
  web: (base, suffix, domain, currentOrigin) =>
    `https://${base}${
      domain === "canopy" ? "-" : "."
    }${suffix}.${domain}.ninja`,
  api: (base, suffix, domain, currentOrigin) => `/wg`,
  auth: (base, suffix, domain, currentOrigin) =>
    base === "skymonkey"
      ? `https://app-${suffix}.${domain}.ninja`
      : currentOrigin,
  workflow: (base, suffix, domain, currentOrigin) => ``,
  skymonkey: (base, suffix, domain, currentOrigin) =>
    `https://skymonkey-${suffix}.canopy.ninja`,
};

export const prodUrls = {
  web: (base, domain, currentOrigin) => `https://${base}.${domain}.com`,
  api: (base, domain, currentOrigin) => `/wg`,
  auth: (base, domain, currentOrigin) =>
    base === "skymonkey" ? `https://app.canopytax.com` : currentOrigin,
  workflow: (base, domain, currentOrigin) => ``,
  skymonkey: (base, domain, currentOrigin) => `https://skymonkey.canopytax.com`,
};

let urlOverrides = {};

export function getEnvironment(origin = window.location.origin) {
  origin = ensureValidOrigin(origin);

  let stageMatch;

  if (origin.match(devPattern)) {
    return DEVELOPMENT_ENVIRONMENT;
  } else if ((stageMatch = origin.match(stagePattern))) {
    const isInteg = stageMatch[3] === "integ";
    return isInteg ? INTEGRATION_ENVIRONMENT : STAGING_ENVIRONMENT;
  } else if (origin.match(prodPattern)) {
    return PRODUCTION_ENVIRONMENT;
  }

  throw new Error(
    `The url ${origin} does not appear to be a development, staging, nor production url`
  );
}

export function ensureValidOrigin(origin) {
  if (!origin) {
    //IE 10 doesn't have the window.location.origin property
    origin =
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "");
  }

  return origin;
}

export function getUrl(type) {
  return function (origin = window.location.origin) {
    origin = ensureValidOrigin(origin);

    if (urlOverrides[type]) {
      return urlOverrides[type];
    }

    const dev = origin.match(devPattern);
    const stage = origin.match(stagePattern);
    const prod = origin.match(prodPattern);

    if (dev) {
      return devUrls[type](dev[2], dev[3], dev[4], dev[5], origin);
    }

    if (stage) {
      return stageUrls[type](stage[2], stage[3], stage[4], origin);
    }

    if (prod) {
      return prodUrls[type](prod[2], prod[3], origin);
    }

    return "";
  };
}

export function navigateToUrl(obj) {
  return window.singleSpaNavigate.apply(this, arguments);
}

export function getBrandingEnvironment(origin = window.location.origin) {
  origin = ensureValidOrigin(origin);
  return origin.includes(".clientportal.")
    ? WHITELABEL_ENVIRONMENT
    : "not white labeled";
}

export function getCDNUrl() {
  const env = getEnvironment();

  if (env === DEVELOPMENT_ENVIRONMENT || env === INTEGRATION_ENVIRONMENT) {
    return "https://cdn-integ.canopy.ninja";
  } else if (env === STAGING_ENVIRONMENT) {
    return "https://cdn-stage.canopy.ninja";
  } else if (env === PRODUCTION_ENVIRONMENT) {
    return "https://cdn.canopytax.com";
  }
}

export const getWebUrl = getUrl("web");
export const getAuthUrl = getUrl("auth");
export const getWorkflowUrl = getUrl("workflow");
export const getSkymonkeyUrl = getUrl("skymonkey");
export const getAPIUrl = getUrl("api");
