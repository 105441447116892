import React from 'react';
import { hidePageNotFound, NavContent } from './page-not-found.app.js';

export default class PageNotFound extends React.Component {
  constructor() {
    super();
    window.addEventListener('hashchange', hidePageNotFound);
  }
  render() {
    return (
      <NavContent
        hasTopnavSecondary={true}
        contactMenuPossible={false}
        style={{marginLeft: 'auto', marginRight: 'auto'}}
      >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <div className="cps-card cps-fixed-focus">
            <div className="cps-card_body cps-padding-16 cps-gray-10" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div>
                <img src="https://cdn.canopytax.com/static/workflow-ui/safe.png" style={{marginTop: '4px'}}/>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '24px'}}>
                <div className="cps-subheader-sm cps-padding-bottom-4">
                  <strong>
                    Page not found
                  </strong>
                </div>
                <div>
                  The page you requested does not exist
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavContent>
    );
  }
  componentWillUnmount() {
    window.removeEventListener('hashchange', hidePageNotFound);
  }
}
