import React from 'react';
import ReactDOM from 'react-dom';
import rootComponent from './page-not-found.component.js';
import singleSpaReact from 'single-spa-react';
import singleSpaCanopy from 'single-spa-canopy';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent,
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  mainContentTransition: true,
  domElementGetter,
  childAppName: 'canopy-urls',
  React,
});

export let NavContent = null;

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
  /* We need the NavContent react component from primary-navbar, but we don't want
   * a synchronous dependency on primary-navbar since canopy-urls is critical to the
   * bootstrap times of the entire app-loader-ui app. So instead, we only synchronously
   * wait on the primary-navbar when we specifically are showing the page not found
   * component in this single spa application.
   */
  function loadNavContentComponent() {
    return SystemJS.import('primary-navbar!sofe')
      .then(primaryNavbar => NavContent = primaryNavbar.NavContent)
  },
];

export const mount = [
  reactLifecycles.mount,
  canopyLifecycles.mount,
];

export const unmount = [
  reactLifecycles.unmount,
  canopyLifecycles.unmount,
];

function domElementGetter() {
  let el = document.getElementById('page-not-found');
  if (!el) {
    el = document.createElement('div');
    el.setAttribute('id', 'page-not-found');
    document.body.appendChild(el)
  }
  return el;
}

export function showPageNotFound() {
  window.history.replaceState({pageNotFound: true}, null);
}

export function hidePageNotFound() {
  window.history.replaceState({pageNotFound: false}, null);
}
